import React from 'react';
import { BookingRequest, BookingRequestStatus } from 'graphql/types.generated';
import { useTransitionBookingRequestToStatusMutation } from 'graphql/mutations/bookingRequest/generated/TransitionBookingRequestToStatus';
import Button from 'components/common/Button';
import { useBookingRequestStatusQuery } from 'graphql/queries/bookingRequest/generated/BookingRequestStatus';
import { ArrowCounterclockwise, Check, X } from 'react-bootstrap-icons';
import { BookingRequestAppointmentsDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestAppointments';
import { TotalBookingRequestsDocument } from 'graphql/queries/bookingRequest/generated/TotalBookingRequests';
import { useTranslation } from 'react-i18next';
import { formatGraphqlErrors } from 'utils/helpers';
import { toast } from 'react-toastify';

interface IControlsProps {
  bookingRequest: Pick<BookingRequest, 'id' | 'status'>;
}

const Controls: FC<IControlsProps> = ({ bookingRequest }) => {
  const { t } = useTranslation();
  const [transitionBookingRequestToStatusMutation, { loading }] = useTransitionBookingRequestToStatusMutation({
    refetchQueries: [
      { query: BookingRequestAppointmentsDocument, variables: { id: bookingRequest.id } },
      { query: TotalBookingRequestsDocument },
    ],
    onError: (error) => {
      toast.error(formatGraphqlErrors(error).message);
    },
  });
  return (
    <div className="d-flex justify-content-end gap-2">
      {bookingRequest.status !== BookingRequestStatus.NEW ? (
        <Button
          disabled={loading}
          endIcon={<ArrowCounterclockwise />}
          variant="outline-primary"
          onClick={() => {
            transitionBookingRequestToStatusMutation({
              variables: { id: bookingRequest.id, status: BookingRequestStatus.NEW },
            });
          }}
        >
          {t('bookingRequestView.reopen')}
        </Button>
      ) : null}
      {bookingRequest.status !== BookingRequestStatus.CANCELED ? (
        <Button
          disabled={loading}
          endIcon={<X />}
          variant="outline-primary"
          onClick={() => {
            transitionBookingRequestToStatusMutation({
              variables: { id: bookingRequest.id, status: BookingRequestStatus.CANCELED },
            });
          }}
        >
          {t('bookingRequestView.cancel')}
        </Button>
      ) : null}
      {bookingRequest.status !== BookingRequestStatus.ACCEPTED ? (
        <Button
          disabled={loading}
          endIcon={<Check />}
          onClick={() => {
            transitionBookingRequestToStatusMutation({
              variables: { id: bookingRequest.id, status: BookingRequestStatus.ACCEPTED },
            });
          }}
        >
          {t('bookingRequestView.confirm')}
        </Button>
      ) : null}
    </div>
  );
};

const ControlsDataLoader: FC<{ bookingRequestId: string }> = ({ bookingRequestId }) => {
  const { data } = useBookingRequestStatusQuery({ variables: { id: bookingRequestId } });
  if (!data) return null;
  return <Controls bookingRequest={data.bookingRequest} />;
};

export default ControlsDataLoader;
